<template>
  <v-row v-if="tmpParams != null">
    <v-col
      cols="12"
      lg="4"
    >
      <EditField
        @change="$emit('setDirty')"
        v-model="tmpParams.maxCrefoIndex"
        :label="texts.de.maxCrefoIndex + $t('base.mandatory')"
        :placeholder="$t('base.value')"
        :clearable="!readonly"
        dense
        :readonly="readonly"
        type="uint"
        :rules="[rules.crefoIndexValue]"
      />
    </v-col>
  </v-row>
</template>

<script lang="js">
import EditField from '@/common/EditField';

export default {
  name: 'ConditionDealerSettingsYareto',
  components: {
    EditField
  },
  props: {
    params: { type: Object, required: true },
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      tmpParams: null,
      rules: {
        crefoIndexValue: (value) => (this.$filters.formatInput(value, 'uint') != null && this.$filters.formatInput(value, 'uint') >= 100 && this.$filters.formatInput(value, 'uint') <= 600) || this.texts.de.crefoIndexValue
      },
      texts: {
        de: {
          maxCrefoIndex: 'höchster zulässiger Crefo-Index (100-600)',
          crefoIndexValue: 'Es muss ein Wert zwischen 100 und 600 gesetzt werden.'
        }
      }
    };
  },
  computed: {},
  watch: {
    params () {
      this.tmpParams = this.params;
    }
  },
  mounted () {
    this.tmpParams = this.params;
  },
  methods: {
    getCustomParams (condition) {
      if (this.tmpParams.maxCrefoIndex == null) delete condition.additionalParams.maxCrefoIndex;
      else condition.additionalParams.maxCrefoIndex = this.tmpParams.maxCrefoIndex;
    },
    validateCustomParams (condition, messages) {
      if (this.rules.crefoIndexValue(condition.additionalParams.maxCrefoIndex) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.texts.de.maxCrefoIndex });
    }
  }
};
</script>

<style scoped>
</style>
